import styled from 'styled-components'

import { Button } from 'rebass'

const Btn = styled(Button)`
  background: var(--color-red);
  transition: background 0.5s;
  cursor: pointer;
  &:hover {
    background: var(--color-darkgray);
  }
`

export default Btn
