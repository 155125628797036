import React, { useState } from 'react'

import { Text, Box } from 'rebass'
import styled from 'styled-components'
import { space, width, fontSize, color } from 'styled-system'

import Layout from '../components/layout'
import SEO from '../components/seo'

import line from '../line'
import Button from '../Button'

const Label = styled.label`
  ${space}
  ${width}
  ${fontSize}
  ${color}
`
const Input = styled.input`
  ${space}
  ${width}
  ${fontSize}
  ${color}
`
const StyledTextarea = styled.textarea`
  ${space}
  ${width}
  ${fontSize}
  ${color}
`
const StyledSelect = styled.select`
  ${space}
  ${width}
  ${fontSize}
  ${color}
`

const Field = ({ label, onChange, required, name, type, Cmp, ...props }) => {
  Cmp = Cmp || Input
  return (
    <Label color={'textgray'} mb={4} style={{ display: 'block' }}>
      <Text fontSize={1}>{label}</Text>
      <Cmp
        {...props}
        mt={2}
        p={2}
        width={1}
        fontSize={1}
        color="textgray"
        style={{
          border: '1px solid var(--color-fieldbordergray)',

          minWidth: 250,
          width: '100%',
        }}
        name={name}
        defaultValue=""
        onChange={event => {
          onChange(name, event.target.value)
        }}
        required
        type={type || 'text'}
      />
    </Label>
  )
}
const Textarea = props => {
  return <Field {...props} rows={6} Cmp={StyledTextarea} />
}
const SelectField = ({ ...props }) => {
  return <Field {...props} Cmp={StyledSelect} />
}

const ContactForm = () => {
  const [formValue, setFormValue] = useState({})
  const onChange = (name, value) => {
    setFormValue({
      ...formValue,
      [name]: value,
    })
  }
  return (
    <Box ml={4}>
      <form
        name="contact"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value="contact" />
        <Field
          onChange={onChange}
          label="Nume și prenume"
          required
          name="name"
        />
        <Field
          onChange={onChange}
          label="Email"
          required
          name="email"
          type="email"
        />
        <Field
          onChange={onChange}
          label="Telefon"
          required
          name="phone"
          type="phone"
        />
        <Field
          onChange={onChange}
          label="Biserica și localitatea"
          name="place"
        />
        <SelectField
          onChange={onChange}
          label="Subiect"
          name="subject"
          text
          children={
            <>
              <option value="carte">Solicitare carte</option>
              <option value="altele">Întrebări de interes</option>
            </>
          }
        />
        <Textarea onChange={onChange} label="Conținut mesaj" name="text" />
        <div data-netlify-recaptcha="true" />
        <Button type="submit">Trimite</Button>
      </form>
    </Box>
  )
}

const B = ({ title, children }) => {
  return (
    <Box my={3} ml={4} color="textgray">
      <h4
        style={{
          display: 'inline-block',
          marginBottom: 5,
          borderBottom: `1px solid var(--color-bordergray)`,
        }}
      >
        {title}
      </h4>
      <Text>{children}</Text>
    </Box>
  )
}

const ContactPage = () => (
  <Layout>
    <Box>
      <SEO title="Contact" keywords={[`edicație biblică`, `fundația ebe`]} />
      {line}
      <h3>Adresă și telefon</h3>
      <B title={'Adresa Sediu:'}>
        Strada Miko Imre, nr. 8, Etaj: P, biroul nr. 3, Cluj-Napoca
      </B>

      <B title={'Telefon mobil:'}>0722 391 445</B>
      {line}
      <B title={'Adresa depozit:'}>Strada Inului, nr. 2-5, Cluj-Napoca</B>

      <B title={'Telefon mobil:'}>0722 391 445</B>
      {line}
    </Box>

    <Box>
      <h3>Formular de contact</h3>
      <ContactForm />
    </Box>
  </Layout>
)

export default ContactPage
